<template>
  <div>
    <Header />
      <div class="grayback">
        <router-view class="bigcontainer"></router-view>
      </div>
    <Footer />
  </div>
</template>


<script>
  import Footer from '@/components/Footer'
  import Header from '@/components/Header'
  
  export default {
    components: {
      Header,
      Footer
    },
  }
</script>


<style scoped>
.grayback{
  background-color: #f7f7f7;
  min-height: 100vh;
}
.bigcontainer {
  position: relative;
  margin: auto;
  max-width: 1164px;
  padding-bottom: 40px;
}
.app-container {
  padding: 20px;
}
</style>
